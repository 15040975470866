import "./laas.scss";
import React from "react";
import { graphql, StaticQuery } from "gatsby";
import { PageTitle } from "../../components/title/title";
import Helmet from "react-helmet";
import AmazeeIoMetadata from "../../components/metadata";
import AmazeePage from "../../components/layout";
import { AmazeeIOButton } from "../../components/button/button";
import Linked from "../../utils/linkedItem";
import RichTextRender from "../../utils/rich-text";
import usePreviewData from "../../utils/usePreviewData";

import {
  getPage,
  TypeLaasPageQueryResult,
  TypeLaasPageData,
} from "../../utils/queries/laas";

import classNames from "classnames";

const query = graphql`
  {
    allPrismicLaas {
      edges {
        node {
          data {
            page_meta_description
            page_meta_title
            page_meta_thumbnail {
              url
            }
            main_image {
              alt
              url
            }
            title3 {
              ...PrismicStructuredTextTypeFragment
            }
            subtitle3 {
              ...PrismicStructuredTextTypeFragment
            }
            workings_items {
              working_image {
                alt
                url
              }
              working_text {
                ...PrismicStructuredTextTypeFragment
              }
            }
            text {
              ...PrismicStructuredTextTypeFragment
            }
            control_text_1 {
              ...PrismicStructuredTextTypeFragment
            }
            control_text_2 {
              ...PrismicStructuredTextTypeFragment
            }
            title2 {
              ...PrismicStructuredTextTypeFragment
            }
            question_checkpoint {
              text {
                ...PrismicStructuredTextTypeFragment
              }
            }
            subtitle1 {
              ...PrismicStructuredTextTypeFragment
            }
            question_point {
              title3
              text {
                ...PrismicStructuredTextTypeFragment
              }
            }
            title1 {
              ...PrismicStructuredTextTypeFragment
            }
            button_text
            button_link_to {
              document {
                ... on PrismicPageDefinitions {
                  data {
                    ...PrismicPageDefinitionsDataTypeFragment
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  fragment PrismicStructuredTextTypeFragment on PrismicStructuredTextType {
    html
    raw
    text
  }

  fragment PrismicPageDefinitionsDataTypeFragment on PrismicPageDefinitionsDataType {
    url
    is_external
    friendly_name
  }
`;

const LaasQueryPage = ({
  data,
  location,
}: {
  location: Location;
  data: TypeLaasPageQueryResult;
}) => {
  const pageData = getPage(data);
  if (!pageData) return null;
  const result = usePreviewData(
    pageData,
    "PrismicLaas",
    true
  ) as TypeLaasPageData;

  return (
    <AmazeePage location={location}>
      <div id="laas-page">
        <PageTitle title={result.page_meta_title} amazeeOnly={true} />
        <Helmet>
          <link
            rel="stylesheet"
            href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/solid.min.css"
          />
          <link rel="stylesheet" href="/css/carousel-multi.min.css" />
        </Helmet>
        <AmazeeIoMetadata
          title={result.page_meta_title}
          page_meta_description={result.page_meta_description}
          page_meta_thumbnail={result.page_meta_thumbnail}
          location={location}
        />

        {/* Top page section */}
        <section className="main pt-5 pb-10">
          <div className="container">
            <div className="row mt-10 justify-center">
              <div className="col-12 col-md-10">
                <div className="row title-text">
                  <div className="col-10 col-md-6">
                    {RichTextRender(result.text)}
                  </div>
                  <div
                    className={classNames(
                      "col-10 col-md-6 bg-cover background-item md:bg-center md:w-3/6 md:m-auto bg-no-repeat bg-contain",
                      {}
                    )}
                    style={{
                      height: 320,
                      backgroundImage: `url('${result.main_image.url}')`,
                    }}
                  />
                </div>
                <div className="row mt-12 control_text">
                  <div className="col-12 col-md-6">
                    {RichTextRender(result.control_text_1)}
                  </div>
                  <div className="col-12 col-md-6 right-side-offset">
                    {RichTextRender(result.control_text_2)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* How does it work */}
        <section className="how-it-works">
          <div className="container">
            <div className="row justify-center">
              <div className="col-12 col-md-10">
                <div className="text-md-center">
                  {RichTextRender(result.title3)}
                  <div className="md-mx-auto how-it-works-subtitle md-col-6">
                    {RichTextRender(result.subtitle3)}
                  </div>
                </div>

                <div className="row mt-12">
                  <div className="col-10 col-md-6">
                    <div
                      className={classNames(
                        "col-10 col-md-4 bg-contain background-item bg-center md:w-3/6 mx-auto bg-no-repeat mb-8",
                        {}
                      )}
                      style={{
                        height: 200,
                        backgroundImage: `url('${result.workings_items[0].working_image.url}')`,
                      }}
                    />
                    {RichTextRender(result.workings_items[0].working_text)}
                  </div>
                  <div className="col-10 col-md-6">
                    <div
                      className={classNames(
                        "col-10 col-md-6 bg-cover background-item bg-center md:w-3/6 mx-auto bg-no-repeat bg-contain mb-8",
                        {}
                      )}
                      style={{
                        height: 200,
                        backgroundImage: `url('${result.workings_items[1].working_image.url}')`,
                      }}
                    />
                    {RichTextRender(result.workings_items[1].working_text)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Who is LaaS for */}
        <section className="uses">
          <div className="container">
            <div className="row justify-center">
              <div className="col-12 text-center">
                {RichTextRender(result.title2)}

                <div className="row justify-center usage-checkpoint mb-10">
                  <div className="col-12 col-md-3 text-center mx-2">
                    <span className="block mb-4"></span>
                    {RichTextRender(result.question_checkpoint[0].text)}
                  </div>
                  <div className="col-12 col-md-3 text-center mx-2">
                    <span className="block mb-4"></span>
                    {RichTextRender(result.question_checkpoint[1].text)}
                  </div>
                  <div className="col-12 col-md-3 text-center mx-2">
                    <span className="block mb-4"></span>
                    {RichTextRender(result.question_checkpoint[2].text)}
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-9">
                <div className="block">
                  <div className="mb-12 text-center subtitle">
                    {RichTextRender(result.subtitle1)}
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <h5 className="heading">
                        {RichTextRender(result.question_point[0].title3)}
                      </h5>
                      {RichTextRender(result.question_point[0].text)}
                    </div>
                    <div className="col-12 col-md-6 flex flex-column justify-between">
                      <h5 className="heading">
                        {RichTextRender(result.question_point[1].title3)}
                      </h5>
                      {RichTextRender(result.question_point[1].text)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Get started CTA */}
        <section className="dark-grey cta">
          <div className="container">
            <div className="row justify-center cta-text">
              <div className="col-12 col-md-7 text-center mb-4 text-white">
                {RichTextRender(result.title1)}
              </div>
              <div className="col-12 text-center cta-button">
                <Linked link_to={result.button_link_to}>
                  <AmazeeIOButton classes="py-2 inverted inline-block px-4">
                    {result.button_text}
                  </AmazeeIOButton>
                </Linked>
              </div>
            </div>
          </div>
        </section>
      </div>
    </AmazeePage>
  );
};

const StaticLaasQueryPage: React.FC<{ location: Location }> = ({
  location,
}) => {
  return (
    <StaticQuery
      query={query}
      render={(data) => <LaasQueryPage location={location} data={data} />}
    />
  );
};
export default StaticLaasQueryPage;
