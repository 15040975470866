import { PrismicImage, PrismicRichText, PrismicPageDefinition } from "./types";

export type TypeLaasPageWorkItem = {
  working_image: PrismicImage;
  working_text: PrismicRichText;
};

export type TypeLaasPageQuestionCheckpoint = {
  text: PrismicRichText;
};

export type TypeLaasPageQuestionPoint = {
  title3: string;
  text: PrismicRichText;
};

export type TypeLaasPageData = {
  page_meta_title: string;
  page_meta_thumbnail: PrismicImage;
  page_meta_description: string;

  main_image: PrismicImage;
  title3: PrismicRichText;
  subtitle3: PrismicRichText;
  workings_items: TypeLaasPageWorkItem[];
  text: PrismicRichText;
  control_text_1: PrismicRichText;
  control_text_2: PrismicRichText;
  title2: PrismicRichText;
  question_checkpoint: TypeLaasPageQuestionCheckpoint[];
  subtitle1: PrismicRichText;
  question_point: TypeLaasPageQuestionPoint[];
  title1: PrismicRichText;
  button_text: string;
  button_link_to: PrismicPageDefinition;
};

export type TypeLaasPageQueryResult = {
  allPrismicLaas: {
    edges: Array<{
      node: { data: TypeLaasPageData };
    }>;
  };
};

export const getPage = (data: TypeLaasPageQueryResult): TypeLaasPageData => {
  return data.allPrismicLaas.edges[0].node.data;
};
